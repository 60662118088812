import { useState, useEffect, useCallback } from "react";
import useWebSocket from "./useWebSocket";
import axios from "axios";
// import { useGlobalState } from "../common/GlobalStateContext"; // 确保路径正确
import { API_URL, WS_URL } from "../../config";

const useCarManager = () => {
    const [brand, setBrand] = useState("");
    const [subcategory, setSubcategory] = useState("");
    const [model, setModel] = useState("");
    const [modelNumber, setModelNumber] = useState("");
    const [startYear, setStartYear] = useState("");
    const [endYear, setEndYear] = useState("");
    const [tabValue, setTabValue] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [brands, setBrands] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [models, setModels] = useState([]);
    const [modelNumbers, setModelNumbers] = useState([]);
    const [vehicleForms, setVehicleForms] = useState([]);
    const [vehicleFormBasePrice, setVehicleFormBasePrice] = useState([]);
    const [vehicleFormPriceCoef, setVehicleFormPriceCoef] = useState([]);
    const [rows, setRows] = useState([]);

    const { messages, sendMessage, isConnected } = useWebSocket();

    const handleWebSocketMessage = useCallback(
        (response) => {
            console.log("WebSocket message received:", response);
            if (response.status !== "success") {
                console.error("WebSocket error:", response);
                return;
            }

            switch (response.action) {
                case "query_brand_categories_response":
                    setBrands(response.data);
                    break;
                case "query_subcategories_response":
                    setSubcategories(response.data);
                    break;
                case "query_models_response":
                    setModels(response.data);
                    break;
                case "query_car_details_response":
                    setModelNumbers(response.data);
                    break;
                case "query_car_response":
                    console.log("Received query_car_response:", response.data);
                    setRows(response.data);
                    // setPage(0);
                    break;
                // case "add_car_response":
                //     handleSearch();
                //     break;
                case "upsert_car_response":
                    // handleSearch();
                    break;
                case "delete_car_response":
                    // handleSearch();
                    break;
                case "search_response":
                    // setRows(response.data);
                    break;
                case "query_all_vehicle_forms_response":
                    console.log(
                        "Received query_all_vehicle_forms_response:",
                        response.data
                    );
                    setVehicleForms(response.data);
                    break;
                case "query_vehicle_form_price_response":
                    console.log(
                        "Received query_vehicle_form_price_response:",
                        response.data
                    );
                    setVehicleFormBasePrice(response.data);
                    break;
                default:
                    console.log("Unknown WebSocket action:", response.action);
            }
        },
        [setBrands, setModels, setRows]
    );

    useEffect(() => {
        if (messages.length > 0) {
            messages.forEach((message) => {
                handleWebSocketMessage(message);
            });
        }
    }, [messages, handleWebSocketMessage]);

    useEffect(() => {
        // 确保WebSocket连接已开启后再发送初始化请求
        const interval = setInterval(() => {
            if (isConnected && brands.length === 0) {
                sendMessage({ action: "query_brand_categories" });
                console.log(
                    "Sending query_brand_categories action via WebSocket"
                );
                handleQueryAllVehicleForms();
                clearInterval(interval);
            }
        }, 100);

        return () => clearInterval(interval);
    }, [isConnected, brands, sendMessage]);

    const handleBrandChange = (event) => {
        const selectedBrand = event.target.value;
        setBrand(selectedBrand);
        setSubcategories([]);
        setModels([]);
        setModelNumbers([]);
        setSubcategory("");
        setModel("");
        setModelNumber("");
        console.log("Sending query_subcategorys action via WebSocket");
        sendMessage({
            action: "query_subcategories",
            category_id: selectedBrand,
        });
    };

    const handleSubcategoriesChange = (event) => {
        const selectedSubcategory = event.target.value;
        setSubcategory(selectedSubcategory);
        setModels([]);
        setModelNumbers([]);
        setModel("");
        setModelNumber("");
        console.log("Sending query_models action via WebSocket");
        sendMessage({
            action: "query_models",
            subcategory_id: selectedSubcategory,
        });
    };

    const handleModelChange = (event) => {
        const selectedModel = event.target.value;
        setModel(selectedModel);
        setModelNumbers([]);
        setModelNumber("");
        setStartYear("");
        setEndYear("");
        // TODO: set year text field blank

        console.log("Sending query_model_numbers action via WebSocket");
        sendMessage({
            action: "query_car_details",
            model_id: selectedModel,
            year_start: startYear,
            year_end: endYear,
        });
    };

    const handleModelNumberChange = (event) => {
        const selectedModelNumber = event.target.value;
        setModelNumber(selectedModelNumber);
        setStartYear("");
        setEndYear("");
        // setModelNumbers([]);
        // TODO: set year text field blank

        // console.log("Sending query_model_numbers action via WebSocket");
        // sendMessage({
        //     action: "query_car_details",
        //     model_id: selectedModel,
        //     year_start: startYear,
        //     year_end: endYear,
        // });
    };

    const handleSearch = () => {
        console.log("Sending search action via WebSocket");
        /** 
        brand_id,
        brand_type_id,
        sub_brand_type_id,
        car_type_id,
        year_start,
        year_end,
         */
        sendMessage({
            action: "query_car",
            params: {
                brand_id: brand,
                brand_type_id: subcategory,
                sub_brand_type_id: model,
                car_type_id: modelNumber,
                year_start: startYear,
                year_end: endYear,
            },
        });
    };

    const handleQueryAllVehicleForms = () => {
        console.log("Sending query_all_vehicle_forms action via WebSocket");
        sendMessage({ action: "query_all_vehicle_forms" });
    };

    const handleQueryVehicleFormPriceCoef = (vehicleFormId) => {
        console.log("Sending query_vehicle_form_price action via WebSocket");
        sendMessage({
            action: "query_vehicle_form_price",
            vehicle_form_id: vehicleFormId,
        });
    };

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRowDoubleClick = (row) => {
        console.log("Row double clicked:", row);
        // Add your logic here, e.g., navigate to a detail page or open a modal
    };

    const filterRows = (status) => {
        return rows.filter((row) => row.status === status);
    };

    const handleAddCar = (car) => {
        console.log("Sending add_car action via WebSocket");
        sendMessage({ action: "add_car", car });
        // 等待1秒后再查询，确保新车辆已经添加到数据库
        setTimeout(() => {
            handleSearch();
        }, 1000);
    };

    const handleUpdateCar = (car) => {
        console.log("Sending update_car action via WebSocket");
        sendMessage({ action: "update_car", car });
        // 等待1秒后再查询，确保新车辆已经添加到数据库
        setTimeout(() => {
            handleSearch();
        }, 1000);
    };

    const handleDeleteCar = (car) => {
        console.log("Sending delete_car action via WebSocket");
        sendMessage({ action: "delete_car", car });
        // 等待1秒后再查询，确保新车辆已经添加到数据库
        setTimeout(() => {
            handleSearch();
        }, 1000);
    };

    const handleUploadImage = (file) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("image", file); // 将文件附加到表单数据中

            // 通过 axios 发送 POST 请求到后端的 upload_image 接口
            axios
                .post(`${API_URL}/api/upload_image`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    if (response.data.status === "success") {
                        resolve(response.data.localPath); // 解析并返回后端返回的本地路径
                    } else {
                        reject(new Error("图片上传失败"));
                    }
                })
                .catch((error) => {
                    reject(new Error(`上传图片时发生错误: ${error.message}`));
                });
        });
    };

    return {
        brand,
        setBrand,
        subcategory,
        setSubcategory,
        model,
        setModel,
        modelNumber,
        setModelNumber,
        startYear,
        setStartYear,
        endYear,
        setEndYear,
        brands,
        subcategories,
        models,
        modelNumbers,
        rows,
        vehicleForms,
        vehicleFormPriceCoef,
        handleBrandChange,
        handleSubcategoriesChange,
        handleModelChange,
        handleModelNumberChange,
        handleSearch,
        handleQueryAllVehicleForms,
        handleQueryVehicleFormPriceCoef,
        tabValue,
        setTabValue,
        page,
        setPage,
        rowsPerPage,
        handleChangeTab,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRowDoubleClick,
        filterRows,
        handleAddCar,
        handleUpdateCar,
        handleDeleteCar,
        handleUploadImage,
        setRowsPerPage,
    };
};

export default useCarManager;
