// WebSocketSingleton.js
// let wsInstance = null;

// export const getWebSocket = () => {
//     return wsInstance;
// };

// export const createWebSocket = (url) => {
//     if (!wsInstance) {
//         wsInstance = new WebSocket(url);
//     }
//     return wsInstance;
// };

// export const closeWebSocket = () => {
//     if (wsInstance) {
//         wsInstance.close();
//         wsInstance = null;
//     }
// };

let wsInstance = null;

export const getWebSocket = () => {
    // 如果 WebSocket 实例存在且状态是打开的，直接返回它
    if (wsInstance && wsInstance.readyState !== WebSocket.CLOSED) {
        return wsInstance;
    }
    return null;
};

export const createWebSocket = (url) => {
    // 如果实例不存在或者它的状态为 CLOSED，创建一个新的 WebSocket 实例
    if (!wsInstance || wsInstance.readyState === WebSocket.CLOSED) {
        wsInstance = new WebSocket(url);
    }
    return wsInstance;
};

export const closeWebSocket = () => {
    if (wsInstance && wsInstance.readyState === WebSocket.OPEN) {
        wsInstance.close();
        wsInstance = null;
    }
};
