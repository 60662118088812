import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";

const ImageCard = ({ title, images }) => {
    // console.log("images:", images);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handlePreviousImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <Box
            className="image-card"
            sx={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: 2,
                height: "auto",
            }}
        >
            <Typography variant="body1">{title}</Typography>
            {images.length > 0 ? (
                <>
                    <img
                        src={images[currentImageIndex]}
                        alt={`图像${currentImageIndex + 1}`}
                        style={{ width: "100%" }}
                        onError={(e) => {
                            console.error("Image failed to load", e);
                            e.target.style.display = "none"; // 隐藏图片以防止占位
                        }}
                    />
                    <Typography variant="body2" style={{ marginTop: "1rem" }}>
                        {`当前图片: ${currentImageIndex + 1}/${images.length}`}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: "1rem" }}
                        onClick={handlePreviousImage}
                    >
                        上一张
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: "1rem", marginLeft: "1rem" }}
                        onClick={handleNextImage}
                    >
                        下一张
                    </Button>
                </>
            ) : (
                <Typography variant="body2" style={{ marginTop: "1rem" }}>
                    暂无图片
                </Typography>
            )}
        </Box>
    );
};

export default ImageCard;
