import React from "react";
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    Typography,
    Box,
    ListItemButton,
    ListItemIcon,
} from "@mui/material";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const Sidebar = () => {
    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: drawerWidth,
                    boxSizing: "border-box",
                    backgroundColor: "#333", // 暗色背景
                    color: "white", // 白色文字
                },
            }}
        >
            <Toolbar>
                <Typography variant="h6" noWrap component="div">
                    {" "}
                    坐享版型数据系统
                </Typography>
            </Toolbar>
            <Box sx={{ overflow: "auto" }}>
                <List>
                    <ListItemButton
                        component={Link}
                        to="/carManager"
                        href="/carManager"
                        sx={{
                            height: 60,
                            "&:hover": {
                                backgroundColor: "#e0e0e0", // 悬停效果
                            },
                        }}
                    >
                        <ListItemText
                            primary="车型和定价管理"
                            primaryTypographyProps={{ fontSize: "1.2rem" }}
                        />
                    </ListItemButton>
                    <ListItemButton
                        component={Link}
                        to="/orderManager"
                        href="/orderManager"
                        sx={{
                            height: 60,
                            "&:hover": {
                                backgroundColor: "#e0e0e0", // 悬停效果
                            },
                        }}
                    >
                        <ListItemText
                            primary="订单管理"
                            primaryTypographyProps={{ fontSize: "1.2rem" }}
                        />
                    </ListItemButton>
                    {/* <ListItemButton
                        component={Link}
                        to="/pricingManager"
                        href="/pricingManager"
                        sx={{
                            height: 60,
                            "&:hover": {
                                backgroundColor: "#e0e0e0", // 悬停效果
                            },
                        }}
                    >
                        <ListItemText
                            primary="定价管理"
                            primaryTypographyProps={{ fontSize: "1.2rem" }}
                        />
                    </ListItemButton> */}
                </List>
            </Box>
        </Drawer>
    );
};

export default Sidebar;
