import { useState, useEffect, useCallback } from "react";
import useWebSocket from "./useWebSocket";
import dayjs from "dayjs";
// import eventEmitter from "./eventEmitter"; // Adjust the path as needed

const useOrderManager = () => {
    const [brand, setBrand] = useState("");
    const [subcategory, setSubcategory] = useState("");
    const [model, setModel] = useState("");
    const [modelNumber, setModelNumber] = useState("");
    const [startYear, setStartYear] = useState("");
    const [endYear, setEndYear] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [brands, setBrands] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [models, setModels] = useState([]);
    const [modelNumbers, setModelNumbers] = useState([]);
    const [rows, setRows] = useState([]);

    const [queryOrderStatus, setQueryOrderStatus] = useState(""); // 新增订单状态
    const [orderStatuses, setOrderStatuses] = useState([]); // 可选的订单状态
    const [databaseCar, setDatabaseCar] = useState("");
    const [orderId, setOrderId] = useState(-1);

    // 定义状态变量
    // const [queryOrderStatus, setQueryOrderStatus] = useState("");
    const [queryWechatId, setQueryWechatId] = useState("");
    const [queryGuessName, setQueryGuessName] = useState("");
    const [queryTelephone, setQueryTelephone] = useState("");
    const [queryInstallLocation, setQueryInstallLocation] = useState("");
    const [
        queryInstallAdministrativeLocation,
        setQueryInstallAdministrativeLocation,
    ] = useState("");
    const [queryInstallDetailLocation, setQueryInstallDetailLocation] =
        useState("");
    const [queryLeatherType, setQueryLeatherType] = useState("");
    const [queryLeatherMaterial, setQueryLeatherMaterial] = useState("");
    const [queryTrackingNumber, setQueryTrackingNumber] = useState("");
    const [queryCourierName, setQueryCourierName] = useState("");
    const [queryCourierCode, setQueryCourierCode] = useState("");
    // const [queryStartTime, setQueryStartTime] = useState("");
    // const [queryEndTime, setQueryEndTime] = useState("");

    // 获取当前时间和7天前的时间
    const currentTime = dayjs().format("YYYY-MM-DDTHH:mm");
    const sevenDaysAgo = dayjs().subtract(7, "day").format("YYYY-MM-DDTHH:mm");

    const [queryStartTime, setQueryStartTime] = useState(sevenDaysAgo);
    const [queryEndTime, setQueryEndTime] = useState(currentTime);

    console.log("useOrderManager");
    const { messages, sendMessage, isConnected } = useWebSocket();

    const handleWebSocketMessage = useCallback((response) => {
        console.log("WebSocket message received:", response);

        switch (response.action) {
            case "query_brand_categories_response":
                setBrands(response.data);
                break;
            case "query_subcategories_response":
                setSubcategories(response.data);
                break;
            case "query_models_response":
                setModels(response.data);
                break;
            case "query_car_details_response":
                setModelNumbers(response.data);
                break;
            case "query_car_response":
                console.log("Received query_car_response:", response.data);
                if (response.data.length > 0) {
                    setDatabaseCar(response.data[0]);
                    console.log("databaseCar:", databaseCar);
                }
                // 将数据填入表格
                // setRows(response.data);
                break;
            case "query_orders_by_status_response":
                setRows(response.data);
                break;
            case "query_order_statuses_response":
                console.log(
                    "Received query_order_statuses_response:",
                    response.data
                );
                setOrderStatuses(response.data); // 处理订单状态数据
                console.log("Order statuses:", orderStatuses);
                break;
            case "add_order_response":
                break;
            case "update_order_response":
                break;
            case "update_order_status_response":
                break;
            case "delete_order_response":
                break;
            default:
                console.log("Unknown WebSocket action:", response.action);
        }
    }, []);

    useEffect(() => {
        console.log("brands updated:", brands);
        console.log("orderStatuses updated:", orderStatuses);
    }, [brands, orderStatuses]);

    useEffect(() => {
        if (messages.length > 0) {
            messages.forEach((message) => {
                handleWebSocketMessage(message);
            });
        }
    }, [messages, handleWebSocketMessage]);

    useEffect(() => {
        // 确保WebSocket连接已开启后再发送初始化请求
        const interval = setInterval(() => {
            if (isConnected && orderStatuses.length === 0) {
                sendMessage({ action: "query_order_statuses" }); // 请求订单状态
                console.log(
                    "Sending query_order_statuses actions via WebSocket"
                );
                sendMessage({ action: "query_brand_categories" });
                clearInterval(interval);
            }
        }, 100);

        return () => clearInterval(interval);
    }, [isConnected, orderStatuses, sendMessage]);

    const handleBrandChange = (event) => {
        const selectedBrand = event.target.value;
        setBrand(selectedBrand);
        setSubcategories([]);
        console.log("Sending query_subcategorys action via WebSocket");
        sendMessage({
            action: "query_subcategories",
            category_id: selectedBrand,
        });
    };

    const handleSubcategoriesChange = (event) => {
        const selectedSubcategory = event.target.value;
        setSubcategory(selectedSubcategory);
        setModels([]);
        console.log("Sending query_models action via WebSocket");
        sendMessage({
            action: "query_models",
            subcategory_id: selectedSubcategory,
        });
    };

    const handleModelChange = (event) => {
        const selectedModel = event.target.value;
        setModel(selectedModel);
        setModelNumbers([]);
        // TODO: set year text field blank

        console.log("Sending query_model_numbers action via WebSocket");
        sendMessage({
            action: "query_car_details",
            model_id: selectedModel,
            year_start: startYear,
            year_end: endYear,
        });
    };

    const handleModelNumberChange = (event) => {
        const selectedModelNumber = event.target.value;
        setModelNumber(selectedModelNumber);
        setStartYear("");
        setEndYear("");
    };

    const handleSearchCar = () => {
        console.log("Sending search action via WebSocket");
        // 清空databaseCar，等待清空完成
        // setDatabaseCar("");
        sendMessage({
            action: "query_car",
            params: {
                brand_id: brand,
                brand_type_id: subcategory,
                sub_brand_type_id: model,
                car_type_id: modelNumber,
                year_start: startYear,
                year_end: endYear,
            },
        });
    };

    const handleSearchOrder = () => {
        console.log("Sending query_orders_by_status action via WebSocket");
        if (queryOrderStatus === "") {
            console.error("Order status is not selected");
            // sendMessage({
            //     action: "query_orders_by_status",
            //     params: {
            //         order_status_id: 4,
            //     },
            // });
            return;
        } else {
            sendMessage({
                action: "query_orders_by_status",
                params: {
                    order_status_id: queryOrderStatus,
                    wechat_order_id: queryWechatId,
                    startTime: queryStartTime,
                    endTime: queryEndTime,
                    guess_name: queryGuessName,
                    telephone: queryTelephone,
                    install_location: queryInstallLocation,
                    install_administrative_location:
                        queryInstallAdministrativeLocation,
                    install_detail_location: queryInstallDetailLocation,
                    leather_type: queryLeatherType,
                    leather_material: queryLeatherMaterial,
                    tracking_number: queryTrackingNumber,
                    courier_name: queryCourierName,
                    courier_code: queryCourierCode,
                },
            });
        }
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleAddOrder = (order) => {
        console.log("Sending add_order action via WebSocket");
        sendMessage({ action: "add_order", order });
        // 等待1秒后再查询，确保新订单已经被添加到数据库
        setTimeout(() => {
            handleSearchOrder();
        }, 1000);
    };

    const handleUpdateOrder = (order) => {
        console.log("Sending update_order action via WebSocket");
        sendMessage({ action: "update_order", order });
        // 等待1秒后再查询，确保新订单已经被添加到数据库
        setTimeout(() => {
            handleSearchOrder();
        }, 1000);
    };

    const handleDeleteOrder = (order) => {
        console.log("Sending delete_order action via WebSocket");
        sendMessage({ action: "delete_order", order });
        // 等待1秒后再查询，确保新订单已经被添加到数据库
        setTimeout(() => {
            handleSearchOrder();
        }, 1000);
    };

    const handleReject = (orderId) => {
        if (orderId <= 0) {
            return;
        }
        // if (orderStatus === 4) {
        //     return;
        // }
        console.log("Sending reject_order action via WebSocket");
        sendMessage({
            action: "update_order_status",
            params: {
                order_id: orderId,
                order_status_id: 6,
            },
        });
        // 等待1秒后再查询，确保新订单已经被添加到数据库
        setTimeout(() => {
            handleSearchOrder();
        }, 1000);
    };

    const handleApprove = (orderId) => {
        if (orderId <= 0) {
            return;
        }
        // if (orderStatus === 4) {
        //     return;
        // }
        console.log("Sending approve_order action via WebSocket");
        sendMessage({
            action: "update_order_status",
            params: {
                order_id: orderId,
                order_status_id: 5,
            },
        });
        // 等待1秒后再查询，确保新订单已经被添加到数据库
        setTimeout(() => {
            handleSearchOrder();
        }, 1000);
    };

    const handleStartProduct = (orderId) => {
        if (orderId <= 0) {
            return;
        }
        console.log("Sending approve_order action via WebSocket");
        sendMessage({
            action: "update_order_status",
            params: {
                order_id: orderId,
                order_status_id: 7,
            },
        });
        // 等待1秒后再查询，确保新订单已经被添加到数据库
        setTimeout(() => {
            handleSearchOrder();
        }, 1000);
    };

    const handleFinishOrder = (orderId) => {
        if (orderId <= 0) {
            return;
        }
        // if (orderStatus === 4) {
        //     return;
        // }
        console.log("Sending approve_order action via WebSocket");
        sendMessage({
            action: "update_order_status",
            params: {
                order_id: orderId,
                order_status_id: 8,
            },
        });
        // 等待1秒后再查询，确保新订单已经被添加到数据库
        setTimeout(() => {
            handleSearchOrder();
        }, 1000);
    };

    const handleDeliver = (
        orderId,
        trackingNumber,
        courierName,
        courierCode
    ) => {
        if (orderId <= 0) {
            return;
        }
        console.log("Sending approve_order action via WebSocket");
        sendMessage({
            action: "update_order_status",
            params: {
                order_id: orderId,
                order_status_id: 9,
                trackingNumber: trackingNumber,
                courierName: courierName,
                courierCode: courierCode,
            },
        });
        // 等待1秒后再查询，确保新订单已经被添加到数据库
        setTimeout(() => {
            handleSearchOrder();
        }, 1000);
    };

    return {
        brand,
        setBrand,
        subcategory,
        setSubcategory,
        model,
        setModel,
        modelNumber,
        setModelNumber,
        startYear,
        setStartYear,
        endYear,
        setEndYear,
        brands,
        subcategories,
        models,
        modelNumbers,
        rows,
        orderStatuses,
        setOrderStatuses,
        handleBrandChange,
        handleSubcategoriesChange,
        handleModelChange,
        handleModelNumberChange,
        handleSearchOrder,
        handleSearchCar,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        // handleShowOrderDetail,
        handleAddOrder,
        handleUpdateOrder,
        handleDeleteOrder,
        databaseCar,
        handleReject,
        handleApprove,
        handleFinishOrder,
        orderId,
        setOrderId,
        handleStartProduct,
        handleDeliver,

        // orderStatus: queryOrderStatus,
        // setOrderStatus: setQueryOrderStatus,
        queryOrderStatus,
        setQueryOrderStatus,
        queryWechatId,
        setQueryWechatId,
        queryGuessName,
        setQueryGuessName,
        queryTelephone,
        setQueryTelephone,
        queryInstallLocation,
        setQueryInstallLocation,
        queryInstallAdministrativeLocation,
        setQueryInstallAdministrativeLocation,
        queryInstallDetailLocation,
        setQueryInstallDetailLocation,
        queryLeatherType,
        setQueryLeatherType,
        queryLeatherMaterial,
        setQueryLeatherMaterial,
        queryTrackingNumber,
        setQueryTrackingNumber,
        queryCourierName,
        setQueryCourierName,
        queryCourierCode,
        setQueryCourierCode,
        queryStartTime,
        setQueryStartTime,
        queryEndTime,
        setQueryEndTime,
    };
};

export default useOrderManager;
