import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { GlobalStateProvider } from "./components/common/GlobalStateContext";

const Root = () => {
    return (
        <React.StrictMode>
            <GlobalStateProvider>
                <App />
            </GlobalStateProvider>
        </React.StrictMode>
    );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
