// GlobalStateContext.js
import React, { createContext, useContext, useState, useCallback } from "react";

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    return (
        <GlobalStateContext.Provider
            value={{
                user,
                setUser,
            }}
        >
            {children}
        </GlobalStateContext.Provider>
    );
};

export const useGlobalState = () => {
    return useContext(GlobalStateContext);
};

// // GlobalStateContext.js
// import React, {
//     createContext,
//     useContext,
//     useRef,
//     useState,
//     useCallback,
// } from "react";

// const GlobalStateContext = createContext();

// export const GlobalStateProvider = ({ children }) => {
//     const tokenRef = useRef(localStorage.getItem("token") || null);
//     const userRef = useRef(null);
//     const wsRef = useRef(null);
//     const listenersRef = useRef([]);
//     const [, forceRender] = useState(0); // 用于强制组件重渲染

//     const setToken = useCallback((token) => {
//         tokenRef.current = token;
//         // localStorage.setItem("token", token);
//         forceRender((prev) => prev + 1); // 强制组件重渲染
//     }, []);

//     const setUser = useCallback((user) => {
//         userRef.current = user;
//         forceRender((prev) => prev + 1); // 强制组件重渲染
//     }, []);

//     const setWs = useCallback((ws) => {
//         wsRef.current = ws;
//     }, []);

//     const addListener = useCallback((listener) => {
//         listenersRef.current = [...listenersRef.current, listener];
//     }, []);

//     const removeListener = useCallback((listener) => {
//         listenersRef.current = listenersRef.current.filter(
//             (l) => l !== listener
//         );
//     }, []);

//     const getListeners = useCallback(() => {
//         return listenersRef.current;
//     }, []);

//     return (
//         <GlobalStateContext.Provider
//             value={{
//                 token: tokenRef.current,
//                 setToken,
//                 user: userRef.current,
//                 setUser,
//                 ws: wsRef.current,
//                 setWs,
//                 addListener,
//                 removeListener,
//                 getListeners,
//             }}
//         >
//             {children}
//         </GlobalStateContext.Provider>
//     );
// };

// export const useGlobalState = () => {
//     return useContext(GlobalStateContext);
// };

// GlobalStateContext.js
// import React, {
//     createContext,
//     useContext,
//     useRef,
//     useState,
//     useCallback,
//     useEffect,
// } from "react";

// const GlobalStateContext = createContext();

// export const GlobalStateProvider = ({ children }) => {
//     const tokenRef = useRef(localStorage.getItem("token") || null);
//     const userRef = useRef(null);
//     const wsRef = useRef(null);
//     const listenersRef = useRef([]);
//     const [shouldReconnect, setShouldReconnect] = useState(false);
//     const [, forceRender] = useState(0); // 用于强制组件重渲染

//     const setToken = useCallback((token) => {
//         tokenRef.current = token;
//         localStorage.setItem("token", token);
//         forceRender((prev) => prev + 1); // 强制组件重渲染
//     }, []);

//     const setUser = useCallback((user) => {
//         userRef.current = user;
//         forceRender((prev) => prev + 1); // 强制组件重渲染
//     }, []);

//     const setWs = useCallback((ws) => {
//         wsRef.current = ws;
//     }, []);

//     const addListener = useCallback((listener) => {
//         listenersRef.current = [...listenersRef.current, listener];
//     }, []);

//     const removeListener = useCallback((listener) => {
//         listenersRef.current = listenersRef.current.filter(
//             (l) => l !== listener
//         );
//     }, []);

//     const getListeners = useCallback(() => {
//         return listenersRef.current;
//     }, []);

//     useEffect(() => {
//         const connectWebSocket = () => {
//             if (tokenRef.current && !wsRef.current) {
//                 console.log("Attempting to connect WebSocket");
//                 wsRef.current = new WebSocket(
//                     `${process.env.REACT_APP_WS_URL}?token=${tokenRef.current}`
//                 );

//                 wsRef.current.onopen = () => {
//                     console.log("WebSocket connection opened");
//                     setWs(wsRef.current); // 更新全局状态中的 ws
//                     setShouldReconnect(true); // 允许重连
//                 };

//                 wsRef.current.onmessage = (message) => {
//                     const parsedData = JSON.parse(message.data);
//                     listenersRef.current.forEach((listener) =>
//                         listener(parsedData)
//                     );
//                 };

//                 wsRef.current.onclose = () => {
//                     console.log("WebSocket connection closed");
//                     setWs(null);
//                     wsRef.current = null;
//                     if (shouldReconnect) {
//                         setTimeout(() => {
//                             if (!wsRef.current) {
//                                 // 确保没有活跃的 WebSocket 实例
//                                 connectWebSocket();
//                             }
//                         }, 5000); // 等待 5 秒后尝试重连
//                     }
//                 };

//                 wsRef.current.onerror = (error) => {
//                     console.error("WebSocket error:", error);
//                     wsRef.current.close(); // 主动关闭可能有问题的连接
//                 };
//             }
//         };

//         connectWebSocket();

//         // 清理函数确保安全关闭 WebSocket
//         return () => {
//             if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
//                 console.log("Closing WebSocket");
//                 wsRef.current.close();
//             }
//         };
//     }, [tokenRef.current, setWs, listenersRef.current, shouldReconnect]);

//     return (
//         <GlobalStateContext.Provider
//             value={{
//                 token: tokenRef.current,
//                 setToken,
//                 user: userRef.current,
//                 setUser,
//                 ws: wsRef.current,
//                 setWs,
//                 addListener,
//                 removeListener,
//                 getListeners,
//             }}
//         >
//             {children}
//         </GlobalStateContext.Provider>
//     );
// };
//
// export const useGlobalState = () => {
//     return useContext(GlobalStateContext);
// };
