// import React from "react";
// import { AppBar, Toolbar, Typography, Button } from "@mui/material";
// import { Link } from "react-router-dom";

// const Navbar = () => (
//     <AppBar position="static">
//         <Toolbar>
//             <Typography variant="h6" style={{ flexGrow: 1 }}>
//                 坐享后台管理系统
//             </Typography>
//             <Button color="inherit" component={Link} to="/">
//                 Home
//             </Button>
//             <Button color="inherit" component={Link} to="/login">
//                 Login
//             </Button>
//         </Toolbar>
//     </AppBar>
// );

// export default Navbar;

// import React from "react";
// import { Link } from "react-router-dom";
// import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";

// const Navbar = ({ user, newOrderAlert }) => {
//     return (
//         <AppBar position="static">
//             <Toolbar>
//                 <Typography variant="h6" style={{ flexGrow: 1 }}>
//                     坐享后台管理系统
//                 </Typography>
//                 {user && (
//                     <Box>
//                         <Typography
//                             variant="body1"
//                             style={{ marginRight: "1rem" }}
//                         >
//                             {user.name}
//                         </Typography>
//                         {newOrderAlert && (
//                             <Typography
//                                 variant="body1"
//                                 style={{ color: "red" }}
//                             >
//                                 New Order Alert!
//                             </Typography>
//                         )}
//                     </Box>
//                 )}
//             </Toolbar>
//         </AppBar>
//     );
// };

// export default Navbar;

import React from "react";
import { AppBar, Toolbar, Typography, Box } from "@mui/material";

const Navbar = ({ title, user, newOrderAlert }) => {
    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    {title}
                </Typography>
                {user && (
                    <Box>
                        <Typography
                            variant="body1"
                            style={{ marginRight: "1rem" }}
                        >
                            {user.name}
                        </Typography>
                        {newOrderAlert && (
                            <Typography
                                variant="body1"
                                style={{ color: "red" }}
                            >
                                New Order Alert!
                            </Typography>
                        )}
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
