import React, { useState, useEffect } from "react";
import axios from "axios";
import { TextField, Button, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../common/GlobalStateContext";
import createModuleLogger from '../common/logger';
import { API_URL, WS_URL } from '../../config';

const logger = createModuleLogger('LoginPage');

const Background = styled("div")({
    backgroundImage: "url(/assets/images/login.jpeg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const LoginBox = styled(Box)({
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    padding: "2rem",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "400px",
    width: "100%",
    textAlign: "center",
});

const LoginPage = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const { setUser } = useGlobalState();
    const navigate = useNavigate();

    // const [shouldInitWebSocket, setShouldInitWebSocket] = useState(false); // 新增状态变量

    const handleLogin = async () => {
        try {
            // console.log('Starting login process');
            logger.info('Starting login process');
            console.log('api url:', API_URL);
            const response = await axios.post(`${API_URL}/auth/login`, {
                name: username,
                password,
            });
            console.log('Received response:', response);
            // logger.info('Received response:', response);
            const { token, user } = response.data;

            if (user.id !== -1) {
                console.log('User authenticated, storing token');
                localStorage.setItem("token", token);
                setMessage("Login successful");
                setUser(user);
                // setToken(token);

                // WebSocket连接在useWebSocket中自动管理
                // setShouldInitWebSocket(true);

                // wait 3 seconds
                // await new Promise((resolve) => setTimeout(resolve, 3000));

                // 登录成功后重定向到Dashboard页面
                console.log('Navigating to /carManager');
                navigate("/carManager");
            } else {
                console.log('User authentication failed');
                setMessage("Login failed");
            }
        } catch (error) {
            console.error('Login error:', error);
            setMessage("Error occurred");
        }
    };

    // useEffect(() => {
    //     if (shouldInitWebSocket) {
    //         // 初始化 WebSocket 连接
    //         useWebSocket();
    //     }
    // }, [shouldInitWebSocket]); // 当 shouldInitWebSocket 变化时触发

    return (
        <Background>
            <LoginBox>
                <Typography variant="h4" gutterBottom>
                    坐享版型数据系统
                </Typography>
                <TextField
                    label="用户名"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="密码"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                    fullWidth
                    style={{ marginTop: "1rem" }}
                >
                    登录
                </Button>
                {message && (
                    <Typography variant="body1" style={{ marginTop: "1rem" }}>
                        {message}
                    </Typography>
                )}
            </LoginBox>
        </Background>
    );
};

export default LoginPage;
