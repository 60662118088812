import React from "react";
import { useState } from "react";
import {
    TextField,
    Button,
    Container,
    Typography,
    Box,
    MenuItem,
    Grid,
    Tabs,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControl,
    InputLabel,
    Select,
    TablePagination,
    CssBaseline,
    Toolbar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import useCarManager from "../hooks/useCarManager";
import ImageCard from "../layout/ImageCard"; // 引入 ImageCard 组件
import "../../assets/styles/OrderManager.css";
import axios from "axios";
import { API_URL, WS_URL } from '../../config';

const CarManager = () => {
    console.log("CarManager component rendered");
    const {
        brand,
        subcategory,
        model,
        modelNumber,
        startYear,
        setStartYear,
        endYear,
        setEndYear,
        brands,
        subcategories,
        models,
        modelNumbers,
        rows,
        vehicleForms,
        handleBrandChange,
        handleSubcategoriesChange,
        handleModelChange,
        handleModelNumberChange,
        handleSearch,
        handleQueryAllVehicleForms,
        handleQueryVehicleFormPriceCoef,
        handleAddCar,
        handleUpdateCar,
        handleDeleteCar,
        handleUploadImage,
        // imageUrlFromBackend,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        // handleChangePage,
        // handleChangeRowsPerPage,
    } = useCarManager();


    // const [rowsPerPage, setRowsPerPage] = useState(10);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogType, setDialogType] = useState(""); // "add", "edit", "delete"
    const [selectedRow, setSelectedRow] = useState(null);
    const [localImagePaths, setLocalImagePaths] = useState([]);

    // const [selectedVehicleForm, setSelectedVehicleForm] = useState(selectedRow?.vehicle_form_id || "");
    // const [vehicleFormBasePrice, setVehicleFormBasePrice] = useState(selectedRow?.vehicle_form_base_price || "");
    // const [vehicleFormPriceCoef, setVehicleFormPriceCoef] = useState(selectedRow?.vehicle_form_price_coef || "");
    // const [carTypeBasePrice, setCarTypeBasePrice] = useState(selectedRow?.car_type_base_price || "");
    // const [carTypePriceCoef, setCarTypePriceCoef] = useState(selectedRow?.car_type_price_coef || "");

    // 定义状态变量
    const [selectedVehicleForm, setSelectedVehicleForm] = useState("");
    const [vehicleFormBasePrice, setVehicleFormBasePrice] = useState("");
    const [vehicleFormPriceCoef, setVehicleFormPriceCoef] = useState("");
    const [carTypeBasePrice, setCarTypeBasePrice] = useState("");
    const [carTypePriceCoef, setCarTypePriceCoef] = useState("");
    const [automobileBrandName, setAutomobileBrandName] = useState("");
    const [brandTypeName, setBrandTypeName] = useState("");
    const [carType, setCarType] = useState("");
    const [serialNum, setSerialNum] = useState("");
    const [productStartTime, setProductStartTime] = useState("");
    const [productEndTime, setProductEndTime] = useState("");
    const [imageUrl, setImageUrl] = useState(null);

    // const [selectedRow, setSelectedRow] = useState(null); // 存储选中行的 id 或索引

    const handleRowClick = (row) => {
        setSelectedRow(row); // 假设每行有一个唯一的 id
        handleShowImage(row); // 显示图片的函数，需要你根据实际情况实现
    };

    const handleSelectImage = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        // 创建本地文件路径用于显示预览（如果需要）
        const localPath = URL.createObjectURL(file);
        setImageUrl(localPath); // 将本地路径设置为 image_url
    };

    // 处理车辆形态选择变化
    const handleVehicleFormChange = (event) => {
        const selectedFormId = event.target.value;
        setSelectedVehicleForm(selectedFormId);

        // 找到选中的车辆形态对应的基准价
        const selectedForm = vehicleForms.find(form => form.id === selectedFormId);
        if (selectedForm) {
            setVehicleFormBasePrice(selectedForm.base_price);
        }
    };

    const handleChangePage = (event, newPage) => {
        console.log(`Current Page: ${page}, New Page: ${newPage}, Rows Per Page: ${rowsPerPage}`);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page when rows per page changes
    };

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(+event.target.value);
    //     setPage(0);
    // };

    // 处理对话框打开事件
    const handleDialogOpen = (type, row = null) => {
        setDialogType(type);
        setSelectedRow(row);

        if (type === 'edit' && row) {
            setSelectedVehicleForm(row.vehicle_form_id || '');
            setVehicleFormBasePrice(row.vehicle_form_base_price || '');
            setVehicleFormPriceCoef(row.vehicle_form_price_coef || '');
            setCarTypeBasePrice(row.car_type_base_price || '');
            setCarTypePriceCoef(row.car_type_price_coef || '');
            setAutomobileBrandName(row.automobile_brand_name || '');
            setBrandTypeName(row.brand_type_name || '');
            setCarType(row.type || '');
            setSerialNum(row.serial_num || '');
            setProductStartTime(row.product_start_time || '');
            setProductEndTime(row.product_end_time || '');
        } else if (type === 'add') {
            setSelectedVehicleForm('');
            setVehicleFormBasePrice('');
            setVehicleFormPriceCoef('');
            setCarTypeBasePrice('');
            setCarTypePriceCoef('');
            setAutomobileBrandName('');
            setBrandTypeName('');
            setCarType('');
            setSerialNum('');
            setProductStartTime('');
            setProductEndTime('');
        }
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    /**
     * 处理表单提交事件
     */
    const handleDialogConfirm = async () => {
        // 关闭对话框
        setDialogOpen(false);

        // 获取文件输入元素和文件
        const fileInput = document.querySelector('input[type="file"]');
        const file = fileInput ? fileInput.files[0] : null;

        // 根据 `dialogType` 和文件存在情况进行处理
        if (file && (dialogType === 'add' || dialogType === 'edit')) {
            await handleFileUploadAndUpdate(file);
        } else {
            handleDataUpdate();
        }
    };

    /**
     * 处理文件上传并更新数据
     * @param {File} file - 需要上传的文件
     */
    const handleFileUploadAndUpdate = async (file) => {
        try {
            // 等待图片上传完成并获取后端返回的本地路径
            const imageUrlFromBackend = await handleUploadImage(file);

            // 更新数据
            const updatedRow = getUpdatedRow(imageUrlFromBackend);

            // 根据 `dialogType` 进行相应操作
            handleDataOperation(updatedRow);
        } catch (error) {
            console.error('读取和发送图片文件时出错:', error);
        }
    };

    /**
     * 处理数据更新
     */
    const handleDataUpdate = () => {
        // 直接处理其他数据，不涉及文件
        const updatedRow = getUpdatedRow(imageUrl);
        handleDataOperation(updatedRow);
    };

    /**
     * 获取更新后的行数据
     * @param {string} imageUrl - 图片的URL地址
     * @returns {Object} 更新后的行数据
     */
    const getUpdatedRow = (imageUrl) => {
        return {
            ...selectedRow,
            vehicle_form_id: selectedVehicleForm,
            vehicle_form_base_price: vehicleFormBasePrice,
            vehicle_form_price_coef: vehicleFormPriceCoef,
            car_type_base_price: carTypeBasePrice,
            car_type_price_coef: carTypePriceCoef,
            automobile_brand_name: automobileBrandName,
            brand_type_name: brandTypeName,
            type: carType,
            serial_num: serialNum,
            product_start_time: productStartTime,
            product_end_time: productEndTime,
            image_url: imageUrl, // 使用传入的图片URL
        };
    };

    /**
     * 根据对话框类型执行相应的数据操作
     * @param {Object} updatedRow - 更新后的行数据
     */
    const handleDataOperation = (updatedRow) => {
        if (dialogType === "add") {
            handleAddCar(updatedRow);
        } else if (dialogType === "edit") {
            handleUpdateCar(updatedRow);
        } else if (dialogType === "delete") {
            handleDeleteCar(selectedRow);
        }
    };


    const handleShowImage = async (row) => {
        try {
            if (!row.image_url) {
                console.warn('No image URL available to download.');
                setLocalImagePaths([]); // 设置为空，避免无图片情况下出错
                return;
            }
            // 调用后端API获取签名URL
            const response = await axios.get(`${API_URL}/api/get-image`, {
                params: { imageUrl: row.image_url },
            });

            if (response.data.success) {
                const base64Image = response.data.image;
                const imageElement = `${base64Image}`;
                setLocalImagePaths([imageElement]);
            } else {
                console.error('Failed to fetch signed URL:', response.data.message);
            }
        } catch (err) {
            console.error('Failed to fetch image from OSS:', err);
        }
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
            <CssBaseline />
            <Container
                className="carManager-container"
                sx={{
                    flexGrow: 1,
                    marginBottom: 4,
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    width: "100%",
                    maxWidth: "none",
                }}
            >
                <Grid container spacing={2}>
                    <Box
                        sx={{
                            width: "calc(100% - 48px)", // Adjust for padding
                            p: 2,
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            overflow: "hidden",
                            marginTop: 4,
                            marginLeft: "32px", // Align content
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={1.5}>
                                <TextField
                                    fullWidth
                                    label="品牌"
                                    select
                                    value={brand}
                                    onChange={handleBrandChange}
                                >
                                    {brands.map((brand) => (
                                        <MenuItem
                                            key={brand.id}
                                            value={brand.id}
                                        >
                                            {brand.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={1.5}>
                                <TextField
                                    fullWidth
                                    label="车系"
                                    select
                                    value={subcategory}
                                    onChange={handleSubcategoriesChange}
                                    disabled={!brand}
                                >
                                    {subcategories.map((subcategory) => (
                                        <MenuItem
                                            key={subcategory.id}
                                            value={subcategory.id}
                                        >
                                            {subcategory.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={1.5}>
                                <TextField
                                    fullWidth
                                    label="车型"
                                    select
                                    value={model}
                                    onChange={handleModelChange}
                                    disabled={!subcategory}
                                >
                                    {models.map((model) => (
                                        <MenuItem
                                            key={model.id}
                                            value={model.id}
                                        >
                                            {model.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={1.5}>
                                <TextField
                                    fullWidth
                                    label="车型编号"
                                    select
                                    value={modelNumber}
                                    onChange={handleModelNumberChange}
                                    disabled={!model}
                                >
                                    {modelNumbers.map((modelNumber) => (
                                        <MenuItem
                                            key={modelNumber.id}
                                            value={modelNumber.id}
                                        >
                                            {modelNumber.number}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={1.5}>
                                <TextField
                                    fullWidth
                                    label="起始年份"
                                    value={startYear}
                                    onChange={(e) =>
                                        setStartYear(e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={1.5}>
                                <TextField
                                    fullWidth
                                    label="结束年份"
                                    value={endYear}
                                    onChange={(e) => setEndYear(e.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleSearch()}
                                sx={{ marginTop: 2, marginRight: 2, fontSize: 'large', width: '100px' }} // 增加 fontSize 以放大按钮
                            >
                                查询
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleDialogOpen("add")}
                                sx={{ marginTop: 2, marginRight: 2, fontSize: 'large', width: '100px' }} // 增加 fontSize 以放大按钮
                            >
                                新增
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handleDialogOpen("edit", selectedRow)}
                                disabled={!selectedRow}
                                sx={{ marginTop: 2, marginRight: 2, fontSize: 'large', width: '100px' }} // 增加 fontSize 以放大按钮
                            >
                                修改
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleDialogOpen("delete", selectedRow)}
                                disabled={!selectedRow}
                                sx={{ marginTop: 2, marginRight: 2, fontSize: 'large', width: '100px' }} // 增加 fontSize 以放大按钮
                            >
                                删除
                            </Button>
                        </Grid>


                    </Box>

                    <Box sx={{
                        width: "calc(100% - 48px)", // Adjust for padding
                        p: 2,
                        border: "1px solid #ddd",
                        borderRadius: "8px",
                        overflow: "hidden",
                        marginTop: 4,
                        marginLeft: "32px", // Align content
                    }}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>品牌</TableCell>
                                        <TableCell>子品牌</TableCell>
                                        <TableCell>车型</TableCell>
                                        <TableCell>车型编号</TableCell>
                                        <TableCell>起始年份</TableCell>
                                        <TableCell>终止年份</TableCell>
                                        <TableCell>车辆形态</TableCell>
                                        <TableCell>车辆形态系数</TableCell>
                                        <TableCell>车型系数</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .slice(
                                            page * rowsPerPage,
                                            Math.min(page * rowsPerPage + rowsPerPage, rows.length)
                                        )
                                        .map((row) => (
                                            <TableRow
                                                key={row.id}
                                                onClick={() => handleRowClick(row)}
                                                selected={selectedRow === row.id} // 判断是否选中
                                                sx={{
                                                    cursor: 'pointer', // 可选：增加手型指针样式
                                                    '&.Mui-selected, &.Mui-selected:hover': {
                                                        backgroundColor: '#f0f0f0', // 自定义选中颜色
                                                    },
                                                }}
                                            >
                                                <TableCell>
                                                    {row.automobile_brand_name}
                                                </TableCell>
                                                <TableCell>
                                                    {row.brand_type_name}
                                                </TableCell>
                                                <TableCell>
                                                    {row.type}
                                                </TableCell>
                                                <TableCell>
                                                    {row.serial_num}
                                                </TableCell>
                                                <TableCell>
                                                    {row.product_start_time}
                                                </TableCell>
                                                <TableCell>
                                                    {row.product_end_time}
                                                </TableCell>
                                                <TableCell>
                                                    {row.vehicle_type_name}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        row.vehicle_form_price_coef
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        row.car_type_price_coef
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>

                    <Grid item sx={{
                        width: "calc(100% - 48px)", // Adjust for padding
                        p: 2,
                        border: "1px solid #ddd",
                        borderRadius: "8px",
                        overflow: "hidden",
                        marginTop: 4,
                        marginLeft: "32px", // Align content
                    }}>
                        <ImageCard title="数据库数据" images={localImagePaths} />
                    </Grid>
                </Grid>
            </Container>

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>
                    {dialogType === "add"
                        ? "新增记录"
                        : dialogType === "edit"
                            ? "修改记录"
                            : "删除记录"}
                </DialogTitle>
                {/* <DialogContent>
                    {dialogType === "delete" ? (
                        <Typography>确定要删除吗？</Typography>
                    ) : (
                        <Box
                            component="form"
                            sx={{ "& .MuiTextField-root": { m: 1 } }}
                        >
                            <TextField
                                label="品牌"
                                fullWidth
                                defaultValue={selectedRow?.automobile_brand_name || ""}
                                disabled={dialogType === "delete"}
                            />
                            <TextField
                                label="子品牌"
                                fullWidth
                                defaultValue={selectedRow?.brand_type_name || ""}
                                disabled={dialogType === "delete"}
                            />
                            <TextField
                                label="车型"
                                fullWidth
                                defaultValue={selectedRow?.type || ""}
                                disabled={dialogType === "delete"}
                            />
                            <TextField
                                label="车型编号"
                                fullWidth
                                defaultValue={selectedRow?.serial_num || ""}
                                disabled={dialogType === "delete"}
                            />
                            <TextField
                                label="起始年份"
                                fullWidth
                                defaultValue={selectedRow?.product_start_time || ""}
                                disabled={dialogType === "delete"}
                            />
                            <TextField
                                label="终止年份"
                                fullWidth
                                defaultValue={selectedRow?.product_end_time || ""}
                                disabled={dialogType === "delete"}
                            />
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel>车辆形态</InputLabel>
                                <Select
                                    value={selectedVehicleForm}
                                    onChange={handleVehicleFormChange}
                                    disabled={dialogType === "delete"}
                                    fullWidth
                                >
                                    {vehicleForms.map((form) => (
                                        <MenuItem key={form.id} value={form.id}>
                                            {form.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                label="车辆形态基准价"
                                fullWidth
                                value={vehicleFormBasePrice}
                                disabled
                            />

                            <TextField
                                label="车辆形态系数"
                                fullWidth
                                value={vehicleFormPriceCoef}
                                onChange={(e) => setVehicleFormPriceCoef(e.target.value)}
                                disabled={dialogType === "delete"}
                            />

                            <TextField
                                label="车型基准价"
                                fullWidth
                                value={carTypeBasePrice}
                                onChange={(e) => setCarTypeBasePrice(e.target.value)}
                                disabled={dialogType === "delete"}
                            />

                            <TextField
                                label="车型系数"
                                fullWidth
                                value={carTypePriceCoef}
                                onChange={(e) => setCarTypePriceCoef(e.target.value)}
                                disabled={dialogType === "delete"}
                            />
                            {dialogType !== "delete" && (
                                <Button variant="contained" component="label">
                                    上传图片
                                    <input type="file" hidden />
                                </Button>
                            )}
                        </Box>
                    )}
                </DialogContent> */}
                <DialogContent>
                    {dialogType === "delete" ? (
                        <Typography>确定要删除吗？</Typography>
                    ) : (
                        <Box component="form" sx={{ "& .MuiTextField-root": { m: 1 } }}>
                            <TextField
                                label="品牌"
                                fullWidth
                                value={automobileBrandName}
                                onChange={(e) => setAutomobileBrandName(e.target.value)}
                                disabled={dialogType === "delete"}
                            />
                            <TextField
                                label="子品牌"
                                fullWidth
                                value={brandTypeName}
                                onChange={(e) => setBrandTypeName(e.target.value)}
                                disabled={dialogType === "delete"}
                            />
                            <TextField
                                label="车型"
                                fullWidth
                                value={carType}
                                onChange={(e) => setCarType(e.target.value)}
                                disabled={dialogType === "delete"}
                            />
                            <TextField
                                label="车型编号"
                                fullWidth
                                value={serialNum}
                                onChange={(e) => setSerialNum(e.target.value)}
                                disabled={dialogType === "delete"}
                            />
                            <TextField
                                label="起始年份"
                                fullWidth
                                value={productStartTime}
                                onChange={(e) => setProductStartTime(e.target.value)}
                                disabled={dialogType === "delete"}
                            />
                            <TextField
                                label="终止年份"
                                fullWidth
                                value={productEndTime}
                                onChange={(e) => setProductEndTime(e.target.value)}
                                disabled={dialogType === "delete"}
                            />
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel>车辆形态</InputLabel>
                                <Select
                                    value={selectedVehicleForm}
                                    onChange={handleVehicleFormChange}
                                    disabled={dialogType === "delete"}
                                    fullWidth
                                >
                                    {vehicleForms.map((form) => (
                                        <MenuItem key={form.id} value={form.id}>
                                            {form.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* <TextField
                                label="车辆形态基准价"
                                fullWidth
                                value={vehicleFormBasePrice}
                                disabled
                            /> */}

                            <TextField
                                label="车辆形态系数"
                                fullWidth
                                value={vehicleFormPriceCoef}
                                onChange={(e) => setVehicleFormPriceCoef(e.target.value)}
                                disabled={dialogType === "delete"}
                            />

                            {/* <TextField
                                label="车型基准价"
                                fullWidth
                                value={carTypeBasePrice}
                                onChange={(e) => setCarTypeBasePrice(e.target.value)}
                                disabled={dialogType === "delete"}
                            /> */}

                            <TextField
                                label="车型系数"
                                fullWidth
                                value={carTypePriceCoef}
                                onChange={(e) => setCarTypePriceCoef(e.target.value)}
                                disabled={dialogType === "delete"}
                            />

                            {dialogType !== "delete" && (
                                <Button variant="contained" component="label">
                                    上传图片
                                    <input type="file" hidden onChange={handleSelectImage} />
                                </Button>
                            )}
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>取消</Button>
                    <Button onClick={handleDialogConfirm}>确认</Button>
                </DialogActions>
            </Dialog>
        </Box >
    );
};

export default CarManager;
