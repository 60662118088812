import log from "loglevel";

// 配置日志级别
log.setLevel(log.levels.INFO);

const createModuleLogger = (moduleName) => {
    return {
        info: (message) => log.info(`[${moduleName}] ${message}`),
        warn: (message) => log.warn(`[${moduleName}] ${message}`),
        error: (message) => log.error(`[${moduleName}] ${message}`),
        debug: (message) => log.debug(`[${moduleName}] ${message}`),
    };
};

export default createModuleLogger;
