// useWebSocket.js
import { useState, useEffect } from "react";
import { API_URL, WS_URL } from "../../config";
import {
    getWebSocket,
    createWebSocket,
    closeWebSocket,
} from "./WebSocketSingleton";

const useWebSocket = () => {
    const [messages, setMessages] = useState([]);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.log("No token available");
            return;
        }

        console.log("Creating WebSocket");

        let ws = getWebSocket();

        if (!ws) {
            ws = createWebSocket(`${WS_URL}?token=${token}`);
            console.log("WebSocket URL:", ws.url);
            console.log("WebSocket created");
        } else {
            console.log("WebSocket already exists");
        }
        // else if (ws.readyState === WebSocket.CLOSED) {
        //     // 如果 ws 已经关闭，重新创建连接
        //     ws = createWebSocket(
        //         `${WS_URL}?token=${token}`
        //     );
        //     console.log("WebSocket URL:", ws.url);
        //     console.log("WebSocket created");
        // }

        console.log("WebSocket state:", ws.readyState);

        ws.onopen = () => {
            console.log("WebSocket connection opened");
            setIsConnected(true);
        };

        ws.onmessage = (message) => {
            const parsedData = JSON.parse(message.data);
            setMessages((prevMessages) => [...prevMessages, parsedData]);
        };

        ws.onclose = (event) => {
            console.log("WebSocket connection closed:", event);
            setIsConnected(false);
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        return () => {
            // if (ws && ws.readyState === WebSocket.OPEN) {
            //     console.log("Closing WebSocket");
            //     ws.close();
            // }
            closeWebSocket();
        };
    }, []);

    const sendMessage = (message) => {
        const ws = getWebSocket();
        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify(message));
        } else {
            console.error("Cannot send message, WebSocket is not open.");
        }
    };

    return { messages, sendMessage, isConnected };
};

export default useWebSocket;
