// import React, { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
    Outlet,
} from "react-router-dom";
import { Box } from "@mui/material";
import LoginPage from "./components/pages/LoginPage";
import CarManager from "./components/pages/CarManager";
import OrderManager from "./components/pages/OrderManager";
import Navbar from "./components/layout/Navbar";
import Sidebar from "./components/layout/Sidebar";
import { useGlobalState } from "./components/common/GlobalStateContext";

const drawerWidth = 240;

const App = () => {
    console.log("App component rendered");
    const { user } = useGlobalState();

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/" element={<MainLayout />}>
                    <Route
                        index
                        element={<Navigate to="/carManager" replace />}
                    />
                    <Route
                        path="carManager"
                        element={
                            user ? (
                                <CarManager />
                            ) : (
                                <Navigate to="/login" replace />
                            )
                        }
                    />
                    <Route
                        path="orderManager"
                        element={
                            user ? (
                                <OrderManager />
                            ) : (
                                <Navigate to="/login" replace />
                            )
                        }
                    />
                </Route>
                <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
        </Router>
    );
};

const MainLayout = () => {
    const { user } = useGlobalState();

    return (
        <Box sx={{ display: "flex" }}>
            <Sidebar />
            <Box
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    width: `calc(100% - ${drawerWidth}px)`,
                }}
            >
                <Navbar title="坐享版型数据系统--车型和定价管理" user={user} />
                <Box sx={{ flexGrow: 1, p: 3, marginTop: 8 }}>
                    <Outlet />{" "}
                    {/* React Router v6's Outlet component renders the current route's children */}
                </Box>
            </Box>
        </Box>
    );
};

export default App;
